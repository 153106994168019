import logo from "./assets/logo/logo_png.png";

function App() {
  return (
    <div>
      <div className="container">
        <img src={logo} alt="logo" />
        <img src={logo} alt="logo" />
        <div style={{ textAlign: "center" }} className="description">
          <small>
            Impressum:
            <br />
            Angaben gem. § 5 TMG: Vegmo GmbH | Innocentiastrasse 5 | 20149
            Hamburg Vertretungsberechtiger Geschдftsfьhrer: Christoph M.
            Kadereit E-Mail: info@vegmo.de Registergericht: Amtsgerichts
            Hamburg, Registernummer: HRB 170578
            Umsatzsteuer-Identifikationsnummer gem. § 27a UStG: DE 345836892
            Inhaltlich Verantwortlicher: Christoph M. Kadereit (Anschrift s.o.)
          </small>
        </div>
      </div>
    </div>
  );
}

export default App;
